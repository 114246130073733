<script>
import { ref } from "vue";
import axios from "axios";

export default {
  name: 'HomeView',
  setup(){
    const vide_input = ref(null)
    return { vide_input }
  },
  data: () => {return{
    file: null,
    frames: [],
    on_sending: false,
    error: null
  }},
  methods: {
    async uploadVideo() {
      this.on_sending = true
      const formData = new FormData();
      formData.append('video', this.$refs.vide_input.files[0]);

      try {
        const response = await axios.post('video/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.frames = response.data.frames
      } catch (error) {
        console.error('Error:', error);
        this.error = error
      }
      this.on_sending = false
    },
  }
}
</script>

<template>
  <header class="d-flex" style="height: 20vh">
    <strong class="m-auto">PARS</strong>
  </header>
  <hr>
  <section>
    <article class="container d-flex">
      <input class="form-control" type="file" accept="video/mp4" ref="vide_input">
      <button class="btn btn-primary btn-sm ms-1 rounded-1" @click="uploadVideo">Catch</button>
    </article>
    <article class="container">
      <hr>
      <strong>Results:</strong>
    </article>
    <article class="container">
      <div v-if="on_sending">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
      <div v-else>
        <div v-if="frames.length === 0" class="position-relative" style="width: 250px; height: 250px;">
          <img class="rounded" style="width: 250px; height: 250px; object-fit: cover" src="../assets/empty.png" alt="empty">
          <button class="position-absolute btn btn-sm btn-light rounded-circle d-flex border-0 shadow" style="right: 10px; bottom: 10px; min-width: 33px; min-height: 33px">
            <i class="fa-solid fa-download link-dark m-auto"></i>
          </button>
        </div>

        <div class="row">
          <div v-for="(frame, index) in frames" v-bind:key="index" class="position-relative col-3 p-1" style="width: 250px; height: 250px;">
            <img class="rounded" style="width: 250px; height: 250px; object-fit: cover" :src="'data:image/png;base64, ' + frame" :alt="'frame_' + index">
            <a class="position-absolute btn btn-sm btn-light rounded-circle d-flex border-0 shadow" :href="'data:image/png;base64, ' + frame" :download="'frame_' + index" style="right: 10px; bottom: 10px; min-width: 33px; min-height: 33px">
              <i class="fa-solid fa-download link-dark m-auto"></i>
            </a>
          </div>
        </div>
      </div>
      <code v-if="error">
        {{error}}
      </code>
    </article>
  </section>
</template>

<style scoped>

</style>
